<template>
  <div class="position-relative z4">
    <div class="c-datatable-header d-flex justify-content-between mb-7 pr-16">
      <div class="d-flex align-items-center">
        <RoleSelect
          width="240px"
          class="mr-4"
          :title="null"
          placeholder="Select an Application"
          endpoint="api/applications"
          :selected="{ id: selectedApp.id, name: selectedApp.name }"
          :onSelected="
            val => {
              selectedApp = val;
              selectedApp.id = val ? val.id : null;
              form.app_id = val.id;
              search = '';
              data = [];
              page = 1;
              loading = true;

              if (selectedRole.id) {
                showRoleAccessddBtn(val.id ? true : false);
                getData();
              }
            }
          "
        />

        <RoleSelect
          v-if="selectedApp.id"
          :title="null"
          placeholder="Select a Role"
          endpoint="api/roles"
          :selected="{ id: selectedRole.id, name: selectedRole.name }"
          :onReset="() => showRoleAccessddBtn(false)"
          :onSelected="
            val => {
              selectedRole = val;
              selectedRole.id = val ? val.id : null;
              form.role_id = val.id;
              search = '';
              data = [];
              page = 1;
              loading = true;

              showRoleAccessddBtn(val.id ? true : false);
              getData();
            }
          "
        />
      </div>

      <div
        v-if="selectedApp.id && selectedRole.id"
        class="d-flex align-items-center"
      >
        <div class="per-page-wrapper mr-4">
          <div>Show:</div>
          <div style="width: 1px; background: #D7D4D3"></div>
          <div>
            <b-dropdown size="sm" variant="link" right no-flip>
              <template v-slot:button-content>
                <div style="margin-left: -15px">{{ perPage }}</div>
              </template>

              <b-dropdown-item @click="perPage = 10">10</b-dropdown-item>
              <b-dropdown-item @click="perPage = 20">20</b-dropdown-item>
              <b-dropdown-item @click="perPage = 50">50</b-dropdown-item>
              <b-dropdown-item @click="perPage = 100">100</b-dropdown-item>
            </b-dropdown>
          </div>
        </div>

        <b-input-group>
          <template #prepend>
            <b-input-group-text>
              <b-icon-search></b-icon-search>
            </b-input-group-text>
          </template>
          <b-form-input
            class="form-control form-control-solid h-auto py-2 pl-3 pr-7"
            placeholder="Search"
            v-model="search"
          ></b-form-input>
        </b-input-group>
      </div>
    </div>

    <div v-if="selectedApp.id && selectedRole.id" class="c-datatable">
      <v-data-table
        :headers="headers"
        :items="data"
        :search="search"
        :items-per-page="perPage"
        :page="page"
        :loading="loading"
        fixed-header
        hide-default-footer
        dense
        @pagination="onPageChanged"
      >
        <template v-slot:[`item.read`]="{ item }">
          <b-form-checkbox
            v-model="item.read"
            :disabled="!item.edited"
            button-variant="primary"
            class="c-datatable-check"
            :value="1"
            :unchecked-value="0"
          >
          </b-form-checkbox>
        </template>

        <template v-slot:[`item.create`]="{ item }">
          <b-form-checkbox
            v-model="item.create"
            :disabled="!item.edited"
            button-variant="primary"
            class="c-datatable-check"
            :value="1"
            :unchecked-value="0"
          >
          </b-form-checkbox>
        </template>

        <template v-slot:[`item.update`]="{ item }">
          <b-form-checkbox
            v-model="item.update"
            :disabled="!item.edited"
            button-variant="primary"
            class="c-datatable-check"
            :value="1"
            :unchecked-value="0"
          >
          </b-form-checkbox>
        </template>

        <template v-slot:[`item.delete`]="{ item }">
          <b-form-checkbox
            v-model="item.delete"
            :disabled="!item.edited"
            button-variant="primary"
            class="c-datatable-check"
            :value="1"
            :unchecked-value="0"
          >
          </b-form-checkbox>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <div class="c-datatable-actions d-flex">
            <div
              v-if="!item.edited"
              v-ripple
              class="c-datatable-edit"
              @click="
                item.edited = true;
                item.old = { ...item };
                data = [...data];
              "
            >
              <img :src="`${baseAppPath}/media/edit-icon.svg`" />
            </div>

            <div
              v-if="item.edited"
              v-ripple
              class="c-datatable-cancel"
              @click="
                if (data.findIndex(o => o.id === item.id) !== -1) {
                  data[data.findIndex(o => o.id === item.id)] = {
                    ...item.old,
                    edited: false
                  };
                }

                data = [...data];
              "
            >
              <img :src="`${baseAppPath}/media/cancel-icon.svg`" />
            </div>

            <div
              v-if="item.edited"
              v-ripple
              class="c-datatable-edit"
              @click="
                item.edited = false;
                data = [...data];
                updateData(item);
              "
            >
              <img :src="`${baseAppPath}/media/save-icon.svg`" />
            </div>

            <div
              v-ripple
              class="c-datatable-delete"
              @click="deleteData(item.id)"
            >
              <img :src="`${baseAppPath}/media/delete-icon.svg`" />
            </div>
          </div>
        </template>
      </v-data-table>

      <div
        class="c-datatable-page d-flex align-items-center justify-content-center mt-8"
      >
        <div
          v-ripple
          @click="page > 1 ? (page -= 1) : undefined"
          class="prev-next-btn"
        >
          <img :src="`${baseAppPath}/media/chevron-left.png`" height="16px" />
        </div>
        <b-form-input
          class="h-auto text-center mx-1 py-2"
          style="height: 40px; width: 50px;"
          v-model="page"
        ></b-form-input>
        <div
          v-ripple
          @click="
            page < pagination.pageCount ? (page = parseInt(page) + 1) : null
          "
          class="prev-next-btn"
        >
          <img
            :src="`${baseAppPath}/media/chevron-left.png`"
            style="transform: rotate(180deg)"
            height="16px"
          />
        </div>
      </div>
    </div>

    <v-app
      v-if="true"
      class="overflow-hidden"
      :style="{ height: dialog ? 'inherit' : '0px' }"
    >
      <v-dialog v-model="dialog" width="400px" persistent>
        <div class="c-dialog">
          <v-card elevation="0">
            <v-card-text class="relative pb-10 px-20 pt-20">
              <div
                class="dialog-close"
                @click="
                  closeDialog();
                  resetForm();
                  selectedData = {};
                "
                v-ripple
              >
                <img
                  :src="`${baseAppPath}/media/close-icon.png`"
                  width="14px"
                  height="14px"
                />
              </div>

              <div class="dialog-title">
                {{
                  selectedData.id ? "Edit Role Access" : "Tambah Role Access"
                }}
              </div>

              <b-form class="form" @submit.stop.prevent="onSubmit">
                <div
                  role="alert"
                  v-bind:class="{ show: errors.length }"
                  class="alert fade alert-danger"
                >
                  <div class="alert-text" v-for="(error, i) in errors" :key="i">
                    {{ error }}
                  </div>
                </div>

                <b-form-group>
                  <RoleSelect
                    v-if="dialog"
                    disabled
                    :title="'Application'"
                    placeholder="Select an Application"
                    endpoint="api/applications"
                    :selected="{ id: selectedApp.id, name: selectedApp.name }"
                    :onSelected="
                      val => {
                        selectedApp = val;
                        selectedApp.id = val ? val.id : null;
                        form.app_id = val.id;
                        search = '';
                        data = [];
                        page = 1;
                        loading = true;

                        getData();
                      }
                    "
                  />
                </b-form-group>

                <b-form-group>
                  <RoleSelect
                    v-if="dialog"
                    disabled
                    :title="'Role'"
                    placeholder="Select a Role"
                    endpoint="api/roles"
                    :selected="{ id: selectedRole.id, name: selectedRole.name }"
                    :onSelected="
                      val => {
                        selectedRole = val;
                        selectedRole.id = val ? val.id : null;
                        form.role_id = val.id;
                        search = '';
                        data = [];
                        page = 1;
                        loading = true;

                        getData();
                      }
                    "
                  />

                  <b-form-invalid-feedback id="input-1-live-feedback">
                    Role is required.
                  </b-form-invalid-feedback>
                </b-form-group>

                <b-form-group>
                  <RoleSelect
                    v-if="dialog && selectedApp.id"
                    height="100px"
                    :title="'Modul'"
                    placeholder="Select a Module"
                    :endpoint="`api/modules?app_id=${selectedApp.id}`"
                    :onSelected="val => (form.module_id = val.id)"
                  />

                  <b-form-invalid-feedback id="input-1-live-feedback">
                    Module is required.
                  </b-form-invalid-feedback>
                </b-form-group>

                <button
                  ref="kt_submit"
                  class="btn btn-primary mx-auto d-block font-weight-bolder px-12 py-3 mb-3 mt-5 font-size-3"
                  style="border-radius: 100px"
                  v-ripple
                >
                  Submit
                </button>
                <!--end::Action-->
              </b-form>
            </v-card-text>
          </v-card>
        </div>
      </v-dialog>
    </v-app>
  </div>
</template>

<style lang="scss">
.c-datatable-check {
  .custom-control-label::before,
  .custom-control-label::after {
    margin-top: -5px;
    width: 1.8rem !important;
    height: 1.8rem !important;
  }
}
</style>
<style lang="scss" scoped>
.per-page-wrapper {
  display: inline-flex;
  color: #a29f9d;
  font-size: 12px;
  font-weight: bold;
  border: 1px solid #d7d4d3;
  border-radius: 12px;

  div:nth-child(1),
  div:nth-child(3) {
    display: flex;
    width: 65px;
    align-items: center;
    justify-content: center;
    // padding: 6px 14px;
  }
  div:nth-child(3) {
    cursor: pointer;
  }
}

.c-datatable-header {
  input {
    background-color: #ffffff !important;
    border: 1px solid #d7d4d3 !important;
    border-left: 0px solid #d7d4d3 !important;
    border-radius: 0px 8px 8px 0px !important;
  }
}

.tab-item {
  padding: 5px;
  width: 80px;
  margin: 0px 5px;
  color: #06b56f;
  font-size: 12px;
  text-align: center;
  border: 1px solid #06b56f;
  border-radius: 8px;
  cursor: pointer;
}
.tab-item-active {
  color: #ffffff;
  font-weight: bold;
  background: #06b56f;
}

.c-datatable-page {
  .prev-next-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 33px;
    height: 33px;
    border-radius: 4px;
    cursor: pointer;

    img {
      display: block;
      height: 16px;
    }
  }
}
</style>

<script>
import { mapState } from "vuex";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import { BToast } from "bootstrap-vue";

import ApiService from "@/core/services/api.service";
import RoleSelect from "@/view/components/RoleSelect/RoleSelect.vue";

export default {
  mixins: [validationMixin],
  props: [
    "dialog",
    "openDialog",
    "closeDialog",
    "confirmationDialog",
    "cofirmationDialogOpened",
    "showRoleAccessddBtn"
  ],
  components: {
    RoleSelect
  },
  data() {
    return {
      search: "",
      headers: [
        { text: "Nama Menu", value: "module.name", width: "30%" },
        { text: "Module Address", value: "module.address", width: "30%" },
        { text: "Read", value: "read", width: "70px" },
        { text: "Create", value: "create", width: "70px" },
        { text: "Update", value: "update", width: "70px" },
        { text: "Delete", value: "delete", width: "70px" },
        { text: "Action", value: "actions", sortable: false }
      ],
      data: [],
      dataModule: [],
      selectedData: {},
      selectedApp: {
        id: null,
        name: null
      },
      selectedRole: {
        id: null,
        name: null
      },

      form: {
        app_id: null,
        module_id: null,
        role_id: null,
        read: 1,
        create: 1,
        update: 1,
        delete: 1
      },

      loading: true,
      page: 1,
      perPage: 10,
      pagination: {
        pageCount: 1
      },

      errors: []
    };
  },
  validations: {
    form: {
      app_id: {
        required
      },
      module_id: {
        required
      },
      role_id: {
        required
      },
      read: {
        required
      },
      create: {
        required
      },
      update: {
        required
      },
      delete: {
        required
      }
    }
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    getData(app_id = this?.selectedApp?.id, role_id = this?.selectedRole?.id) {
      if (app_id && role_id) {
        ApiService.get(`api/module-role`)
          .then(({ data }) => {
            if (data) {
              let _data = data.filter(
                el => el?.module?.client_id == app_id && el?.role?.id == role_id
              );
              this.data = _data;
            }
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      // set spinner to submit button
      const submitButton = this.$refs["kt_submit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      let formData = {
        module_id: this.form.module_id,
        role_id: this.form.role_id,
        read: this.form.read,
        create: this.form.create,
        update: this.form.update,
        delete: this.form.delete
      };

      if (!this?.selectedData?.id) {
        // Create
        ApiService.post("api/module-role", formData)
          .then(data => {
            if (data?.status == 200 || data?.status == 201) {
              this.getData();
              this.closeDialog();
              this.$swal.fire({
                icon: "success",
                title: "Berhasil!",
                text: "Role Access berhasil ditambahkan"
              });
            }
          })
          .finally(() => {
            submitButton.classList.remove(
              "spinner",
              "spinner-light",
              "spinner-right"
            );
          });
      } else {
        // Update
        // ApiService.put(`api/module-role/${this?.selectedData?.id}`, formData)
        //   .then((data) => {
        //     if (data?.status == 200 || data?.status == 201){
        //       this.getData();
        //       this.resetForm();
        //       this.closeDialog();
        //       this.$swal.fire({
        //         icon: 'success',
        //         title: 'Berhasil!',
        //         text: 'Data Role Access berhasil diperbarui',
        //       })
        //     }
        //   })
        //   .finally(() => {
        //     submitButton.classList.remove("spinner", "spinner-light", "spinner-right");
        //   })
      }
    },
    updateData(item) {
      let formData = {
        module_id: item.module_id,
        role_id: this.form.role_id,
        read: item.read,
        create: item.create,
        update: item.update,
        delete: item.delete
      };

      ApiService.put(`api/module-role/${item.id}`, formData).then(data => {
        if (data?.status == 200 || data?.status == 201) {
          this.getData();

          let bootStrapToaster = new BToast();
          bootStrapToaster.$bvToast.toast(
            "Data Role Access berhasil diperbarui",
            {
              title: "Berhasil!",
              toaster: "b-toaster-top-right",
              variant: "success",
              solid: true,
              appendToast: true
            }
          );
        }
      });
    },
    deleteData(id) {
      let _this = this;

      this.$swal({
        title: "Lanjutkan menghapus Role Access?",
        showCancelButton: true,
        confirmButtonText: "Ya",
        cancelButtonText: "Tidak",
        showLoaderOnConfirm: true,
        preConfirm: function(result) {
          return new Promise(function(resolve, reject) {
            if (result) {
              ApiService.delete(`api/module-role/${id}`)
                .then(() => {
                  var index = _this.data.findIndex(o => o.id === id);
                  if (index !== -1) {
                    let _data = _this.data;
                    _data.splice(index, 1);
                    _this.data = _data;
                  }

                  resolve();
                })
                .catch(() => {
                  reject();
                });
            }
          });
        },
        allowOutsideClick: () => !this.$swal.isLoading()
      });
    },
    resetForm() {
      this.form = {
        ...this.form,
        read: 1,
        create: 1,
        update: 1,
        delete: 1
      };

      this.$nextTick(() => {
        this.$v.$reset();
      });
    },

    onPageChanged(page) {
      this.pagination = page;
    }
  },
  computed: {
    ...mapState({
      errors: state => state.account.errors
    })
  }
};
</script>
