<template>
  <div class="position-relative z4">
    <div class="c-datatable-header d-flex justify-content-between mb-7 pr-16">
      <RoleSelect
        width="240px"
        :title="null"
        placeholder="Select an Application"
        endpoint="api/applications"
        :selected="{ id: selectedApp.id, name: selectedApp.name }"
        :onSelected="
          val => {
            selectedApp = val;
            selectedApp.id = val ? val.id : null;
            form.app_id = val.id;
            search = '';
            data = [];
            page = 1;
            loading = true;

            showApiAddBtn(false);
            getData(val.id);
          }
        "
      />

      <div v-if="selectedApp.id" class="d-flex align-items-center">
        <div class="per-page-wrapper mr-4">
          <div>Show:</div>
          <div style="width: 1px; background: #D7D4D3"></div>
          <div>
            <b-dropdown size="sm" variant="link" right no-flip>
              <template v-slot:button-content>
                <div style="margin-left: -15px">{{ perPage }}</div>
              </template>

              <b-dropdown-item @click="perPage = 10">10</b-dropdown-item>
              <b-dropdown-item @click="perPage = 20">20</b-dropdown-item>
              <b-dropdown-item @click="perPage = 50">50</b-dropdown-item>
              <b-dropdown-item @click="perPage = 100">100</b-dropdown-item>
            </b-dropdown>
          </div>
        </div>

        <b-input-group>
          <template #prepend>
            <b-input-group-text>
              <b-icon-search></b-icon-search>
            </b-input-group-text>
          </template>
          <b-form-input
            class="form-control form-control-solid h-auto py-2 pl-3 pr-7"
            placeholder="Search"
            v-model="search"
          ></b-form-input>
        </b-input-group>
      </div>
    </div>

    <div v-if="selectedApp.id" class="c-datatable">
      <v-data-table
        :headers="headers"
        :items="data"
        :search="search"
        :items-per-page="perPage"
        :page="page"
        :loading="loading"
        fixed-header
        hide-default-footer
        dense
        @pagination="onPageChanged"
      >
        <template v-slot:[`item.actions`]="{ item }">
          <div class="c-datatable-actions d-flex">
            <div
              v-ripple
              class="c-datatable-edit"
              @click="
                selectedData = item;
                form = { ...form, ...item };
                openDialog();
              "
            >
              <img :src="`${baseAppPath}/media/edit-icon.svg`" />
            </div>
            <div
              v-ripple
              class="c-datatable-delete"
              @click="deleteData(item.id)"
            >
              <img :src="`${baseAppPath}/media/delete-icon.svg`" />
            </div>
          </div>
        </template>
      </v-data-table>

      <div
        class="c-datatable-page d-flex align-items-center justify-content-center mt-8"
      >
        <div
          v-ripple
          v-on:click="page > 1 ? (page -= 1) : undefined"
          class="prev-next-btn"
        >
          <img :src="`${baseAppPath}/media/chevron-left.png`" height="16px" />
        </div>
        <b-form-input
          class="h-auto text-center mx-1 py-2"
          style="height: 40px; width: 50px;"
          v-model="page"
        ></b-form-input>
        <div
          v-ripple
          v-on:click="
            page < pagination.pageCount ? (page = parseInt(page) + 1) : null
          "
          class="prev-next-btn"
        >
          <img
            :src="`${baseAppPath}/media/chevron-left.png`"
            style="transform: rotate(180deg)"
            height="16px"
          />
        </div>
      </div>
    </div>

    <v-app
      v-if="true"
      class="overflow-hidden"
      :style="{ height: dialog ? 'inherit' : '0px' }"
    >
      <v-dialog v-model="dialog" width="400px" persistent>
        <div class="c-dialog">
          <v-card elevation="0">
            <v-card-text class="relative pb-10 px-20 pt-20">
              <div
                class="dialog-close"
                v-on:click="
                  closeDialog();
                  resetForm();
                  selectedData = {};
                "
                v-ripple
              >
                <img
                  :src="`${baseAppPath}/media/close-icon.png`"
                  width="14px"
                  height="14px"
                />
              </div>

              <div class="dialog-title">
                {{ selectedData.id ? "Edit API" : "Tambah API" }}
              </div>

              <b-form class="form" @submit.stop.prevent="onSubmit">
                <div
                  role="alert"
                  v-bind:class="{ show: errors.length }"
                  class="alert fade alert-danger"
                >
                  <div class="alert-text" v-for="(error, i) in errors" :key="i">
                    {{ error }}
                  </div>
                </div>

                <b-form-group>
                  <RoleSelect
                    v-if="dialog"
                    disabled
                    :title="'Aplikasi'"
                    placeholder="Select an Application"
                    endpoint="api/applications"
                    :selected="selectedApp"
                    :onSelected="val => (form.app_id = val.id)"
                  />

                  <b-form-invalid-feedback id="input-1-live-feedback">
                    Aplikasi is required.
                  </b-form-invalid-feedback>
                </b-form-group>

                <b-form-group>
                  <RoleSelect
                    v-if="dialog"
                    height="100px"
                    :title="'Modul'"
                    placeholder="Select a Module"
                    :endpoint="`api/modules?app_id=${selectedApp.id}`"
                    :onSelected="val => (form.module_id = val.id)"
                  />

                  <b-form-invalid-feedback id="input-1-live-feedback">
                    Module is required.
                  </b-form-invalid-feedback>
                </b-form-group>

                <b-form-group>
                  <div class="input-title">URL</div>
                  <b-form-input
                    class="form-control form-control-solid h-auto py-2 pl-3 pr-7"
                    v-model="$v.form.url.$model"
                    :state="validateState('url')"
                    aria-describedby="input-4-live-feedback"
                  ></b-form-input>

                  <b-form-invalid-feedback id="input-4-live-feedback">
                    URL is required.
                  </b-form-invalid-feedback>
                </b-form-group>

                <b-form-group>
                  <div class="input-title">Method</div>
                  <b-form-input
                    class="form-control form-control-solid h-auto py-2 pl-3 pr-7"
                    v-model="$v.form.method.$model"
                    :state="validateState('method')"
                    aria-describedby="input-4-live-feedback"
                  ></b-form-input>

                  <b-form-invalid-feedback id="input-4-live-feedback">
                    Method is required.
                  </b-form-invalid-feedback>
                </b-form-group>

                <button
                  ref="kt_submit"
                  class="btn btn-primary mx-auto d-block font-weight-bolder px-12 py-3 mb-3 mt-5 font-size-3"
                  style="border-radius: 100px"
                  v-ripple
                >
                  Submit
                </button>
                <!--end::Action-->
              </b-form>
            </v-card-text>
          </v-card>
        </div>
      </v-dialog>
    </v-app>
  </div>
</template>

<style lang="scss" scoped>
.per-page-wrapper {
  display: inline-flex;
  color: #a29f9d;
  font-size: 12px;
  font-weight: bold;
  border: 1px solid #d7d4d3;
  border-radius: 12px;

  div:nth-child(1),
  div:nth-child(3) {
    display: flex;
    width: 65px;
    align-items: center;
    justify-content: center;
    // padding: 6px 14px;
  }
  div:nth-child(3) {
    cursor: pointer;
  }
}

.c-datatable-header {
  input {
    background-color: #ffffff !important;
    border: 1px solid #d7d4d3 !important;
    border-left: 0px solid #d7d4d3 !important;
    border-radius: 0px 8px 8px 0px !important;
  }
}

.tab-item {
  padding: 5px;
  width: 80px;
  margin: 0px 5px;
  color: #06b56f;
  font-size: 12px;
  text-align: center;
  border: 1px solid #06b56f;
  border-radius: 8px;
  cursor: pointer;
}
.tab-item-active {
  color: #ffffff;
  font-weight: bold;
  background: #06b56f;
}

.c-datatable-page {
  .prev-next-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 33px;
    height: 33px;
    border-radius: 4px;
    cursor: pointer;

    img {
      display: block;
      height: 16px;
    }
  }
}
</style>

<script>
import { mapState } from "vuex";
import { validationMixin } from "vuelidate";
import { minLength, required } from "vuelidate/lib/validators";

import ApiService from "@/core/services/api.service";
import RoleSelect from "@/view/components/RoleSelect/RoleSelect.vue";

export default {
  mixins: [validationMixin],
  props: [
    "dialog",
    "openDialog",
    "closeDialog",
    "confirmationDialog",
    "cofirmationDialogOpened",
    "showApiAddBtn"
  ],
  components: {
    RoleSelect
  },
  data() {
    return {
      search: "",
      headers: [
        { text: "API URL", value: "url", width: "30%" },
        { text: "Nama Modul", value: "module.name", width: "30%" },
        { text: "Modul Address", value: "module.address", width: "30%" },
        { text: "Method", value: "method", width: "100px" },
        { text: "Action", value: "actions", sortable: false }
      ],
      data: [],
      dataModule: [],
      selectedData: {},
      selectedApp: {
        id: null,
        name: null
      },

      form: {
        app_id: null,
        module_id: null,
        url: null,
        method: null
      },

      loading: true,
      page: 1,
      perPage: 10,
      pagination: {
        pageCount: 1
      },

      errors: []
    };
  },
  validations: {
    form: {
      app_id: {
        required
      },
      module_id: {
        required
      },
      url: {
        required,
        minLength: minLength(1)
      },
      method: {
        required,
        minLength: minLength(1)
      }
    }
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    getData(app_id = this?.selectedApp?.id) {
      ApiService.get(`api/modules?app_id=${app_id}`)
        .then(({ data }) => {
          if (data) {
            let _data = [];
            for (let i = 0; i < data.length; i++) {
              const api = data[i].api;
              _data = [..._data, ...api];
            }

            this.data = _data;
            this.dataModule = data;
            this.showApiAddBtn(true);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      // set spinner to submit button
      const submitButton = this.$refs["kt_submit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      let formData = {
        module_id: this.form.module_id,
        url: this.form.url,
        method: this.form.method
      };

      if (!this?.selectedData?.id) {
        // Create
        ApiService.post("api/module-api", formData)
          .then(data => {
            if (data?.status == 200 || data?.status == 201) {
              this.getData();
              this.resetForm();
              this.closeDialog();
              this.$swal.fire({
                icon: "success",
                title: "Berhasil!",
                text: "API berhasil ditambahkan"
              });
            }
          })
          .finally(() => {
            submitButton.classList.remove(
              "spinner",
              "spinner-light",
              "spinner-right"
            );
          });
      } else {
        // Update
        ApiService.put(`api/module-api/${this?.selectedData?.id}`, formData)
          .then(data => {
            if (data?.status == 200 || data?.status == 201) {
              this.getData();
              this.resetForm();
              this.closeDialog();
              this.$swal.fire({
                icon: "success",
                title: "Berhasil!",
                text: "Data API berhasil diperbarui"
              });
            }
          })
          .finally(() => {
            submitButton.classList.remove(
              "spinner",
              "spinner-light",
              "spinner-right"
            );
          });
      }
    },
    deleteData(id) {
      let _this = this;

      this.$swal({
        title: "Lanjutkan menghapus API?",
        showCancelButton: true,
        confirmButtonText: "Ya",
        cancelButtonText: "Tidak",
        showLoaderOnConfirm: true,
        preConfirm: function(result) {
          return new Promise(function(resolve, reject) {
            if (result) {
              ApiService.delete(`api/module-api/${id}`)
                .then(() => {
                  var index = _this.data.findIndex(o => o.id === id);
                  if (index !== -1) {
                    let _data = _this.data;
                    _data.splice(index, 1);
                    _this.data = _data;
                  }

                  resolve();
                })
                .catch(() => {
                  reject();
                });
            }
          });
        },
        allowOutsideClick: () => !this.$swal.isLoading()
      });
    },
    resetForm() {
      this.form = {
        ...this.form,
        module_id: null,
        url: null,
        method: null
      };

      this.$nextTick(() => {
        this.$v.$reset();
      });
    },

    onPageChanged(page) {
      this.pagination = page;
    }
  },
  computed: {
    ...mapState({
      errors: state => state.account.errors
    })
  }
};
</script>
