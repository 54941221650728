var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"position-relative z4"},[_c('div',{staticClass:"c-datatable-header d-flex justify-content-between mb-7 pr-16"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('RoleSelect',{staticClass:"mr-4",attrs:{"width":"240px","title":null,"placeholder":"Select an Application","endpoint":"api/applications","selected":{ id: _vm.selectedApp.id, name: _vm.selectedApp.name },"onSelected":function (val) {
            _vm.selectedApp = val;
            _vm.selectedApp.id = val ? val.id : null;
            _vm.form.app_id = val.id;
            _vm.search = '';
            _vm.data = [];
            _vm.page = 1;
            _vm.loading = true;

            if (_vm.selectedRole.id) {
              _vm.showRoleAccessddBtn(val.id ? true : false);
              _vm.getData();
            }
          }}}),(_vm.selectedApp.id)?_c('RoleSelect',{attrs:{"title":null,"placeholder":"Select a Role","endpoint":"api/roles","selected":{ id: _vm.selectedRole.id, name: _vm.selectedRole.name },"onReset":function () { return _vm.showRoleAccessddBtn(false); },"onSelected":function (val) {
            _vm.selectedRole = val;
            _vm.selectedRole.id = val ? val.id : null;
            _vm.form.role_id = val.id;
            _vm.search = '';
            _vm.data = [];
            _vm.page = 1;
            _vm.loading = true;

            _vm.showRoleAccessddBtn(val.id ? true : false);
            _vm.getData();
          }}}):_vm._e()],1),(_vm.selectedApp.id && _vm.selectedRole.id)?_c('div',{staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"per-page-wrapper mr-4"},[_c('div',[_vm._v("Show:")]),_c('div',{staticStyle:{"width":"1px","background":"#D7D4D3"}}),_c('div',[_c('b-dropdown',{attrs:{"size":"sm","variant":"link","right":"","no-flip":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('div',{staticStyle:{"margin-left":"-15px"}},[_vm._v(_vm._s(_vm.perPage))])]},proxy:true}],null,false,4219377712)},[_c('b-dropdown-item',{on:{"click":function($event){_vm.perPage = 10}}},[_vm._v("10")]),_c('b-dropdown-item',{on:{"click":function($event){_vm.perPage = 20}}},[_vm._v("20")]),_c('b-dropdown-item',{on:{"click":function($event){_vm.perPage = 50}}},[_vm._v("50")]),_c('b-dropdown-item',{on:{"click":function($event){_vm.perPage = 100}}},[_vm._v("100")])],1)],1)]),_c('b-input-group',{scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('b-input-group-text',[_c('b-icon-search')],1)]},proxy:true}],null,false,1459523206)},[_c('b-form-input',{staticClass:"form-control form-control-solid h-auto py-2 pl-3 pr-7",attrs:{"placeholder":"Search"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1):_vm._e()]),(_vm.selectedApp.id && _vm.selectedRole.id)?_c('div',{staticClass:"c-datatable"},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.data,"search":_vm.search,"items-per-page":_vm.perPage,"page":_vm.page,"loading":_vm.loading,"fixed-header":"","hide-default-footer":"","dense":""},on:{"pagination":_vm.onPageChanged},scopedSlots:_vm._u([{key:"item.read",fn:function(ref){
          var item = ref.item;
return [_c('b-form-checkbox',{staticClass:"c-datatable-check",attrs:{"disabled":!item.edited,"button-variant":"primary","value":1,"unchecked-value":0},model:{value:(item.read),callback:function ($$v) {_vm.$set(item, "read", $$v)},expression:"item.read"}})]}},{key:"item.create",fn:function(ref){
          var item = ref.item;
return [_c('b-form-checkbox',{staticClass:"c-datatable-check",attrs:{"disabled":!item.edited,"button-variant":"primary","value":1,"unchecked-value":0},model:{value:(item.create),callback:function ($$v) {_vm.$set(item, "create", $$v)},expression:"item.create"}})]}},{key:"item.update",fn:function(ref){
          var item = ref.item;
return [_c('b-form-checkbox',{staticClass:"c-datatable-check",attrs:{"disabled":!item.edited,"button-variant":"primary","value":1,"unchecked-value":0},model:{value:(item.update),callback:function ($$v) {_vm.$set(item, "update", $$v)},expression:"item.update"}})]}},{key:"item.delete",fn:function(ref){
          var item = ref.item;
return [_c('b-form-checkbox',{staticClass:"c-datatable-check",attrs:{"disabled":!item.edited,"button-variant":"primary","value":1,"unchecked-value":0},model:{value:(item.delete),callback:function ($$v) {_vm.$set(item, "delete", $$v)},expression:"item.delete"}})]}},{key:"item.actions",fn:function(ref){
          var item = ref.item;
return [_c('div',{staticClass:"c-datatable-actions d-flex"},[(!item.edited)?_c('div',{directives:[{name:"ripple",rawName:"v-ripple"}],staticClass:"c-datatable-edit",on:{"click":function($event){item.edited = true;
              item.old = Object.assign({}, item);
              _vm.data = [].concat( _vm.data );}}},[_c('img',{attrs:{"src":(_vm.baseAppPath + "/media/edit-icon.svg")}})]):_vm._e(),(item.edited)?_c('div',{directives:[{name:"ripple",rawName:"v-ripple"}],staticClass:"c-datatable-cancel",on:{"click":function($event){if (_vm.data.findIndex(function (o) { return o.id === item.id; }) !== -1) {
                _vm.data[_vm.data.findIndex(function (o) { return o.id === item.id; })] = Object.assign({}, item.old,
                  {edited: false});
              }

              _vm.data = [].concat( _vm.data );}}},[_c('img',{attrs:{"src":(_vm.baseAppPath + "/media/cancel-icon.svg")}})]):_vm._e(),(item.edited)?_c('div',{directives:[{name:"ripple",rawName:"v-ripple"}],staticClass:"c-datatable-edit",on:{"click":function($event){item.edited = false;
              _vm.data = [].concat( _vm.data );
              _vm.updateData(item);}}},[_c('img',{attrs:{"src":(_vm.baseAppPath + "/media/save-icon.svg")}})]):_vm._e(),_c('div',{directives:[{name:"ripple",rawName:"v-ripple"}],staticClass:"c-datatable-delete",on:{"click":function($event){return _vm.deleteData(item.id)}}},[_c('img',{attrs:{"src":(_vm.baseAppPath + "/media/delete-icon.svg")}})])])]}}],null,true)}),_c('div',{staticClass:"c-datatable-page d-flex align-items-center justify-content-center mt-8"},[_c('div',{directives:[{name:"ripple",rawName:"v-ripple"}],staticClass:"prev-next-btn",on:{"click":function($event){_vm.page > 1 ? (_vm.page -= 1) : undefined}}},[_c('img',{attrs:{"src":(_vm.baseAppPath + "/media/chevron-left.png"),"height":"16px"}})]),_c('b-form-input',{staticClass:"h-auto text-center mx-1 py-2",staticStyle:{"height":"40px","width":"50px"},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}}),_c('div',{directives:[{name:"ripple",rawName:"v-ripple"}],staticClass:"prev-next-btn",on:{"click":function($event){_vm.page < _vm.pagination.pageCount ? (_vm.page = parseInt(_vm.page) + 1) : null}}},[_c('img',{staticStyle:{"transform":"rotate(180deg)"},attrs:{"src":(_vm.baseAppPath + "/media/chevron-left.png"),"height":"16px"}})])],1)],1):_vm._e(),(true)?_c('v-app',{staticClass:"overflow-hidden",style:({ height: _vm.dialog ? 'inherit' : '0px' })},[_c('v-dialog',{attrs:{"width":"400px","persistent":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('div',{staticClass:"c-dialog"},[_c('v-card',{attrs:{"elevation":"0"}},[_c('v-card-text',{staticClass:"relative pb-10 px-20 pt-20"},[_c('div',{directives:[{name:"ripple",rawName:"v-ripple"}],staticClass:"dialog-close",on:{"click":function($event){_vm.closeDialog();
                _vm.resetForm();
                _vm.selectedData = {};}}},[_c('img',{attrs:{"src":(_vm.baseAppPath + "/media/close-icon.png"),"width":"14px","height":"14px"}})]),_c('div',{staticClass:"dialog-title"},[_vm._v(" "+_vm._s(_vm.selectedData.id ? "Edit Role Access" : "Tambah Role Access")+" ")]),_c('b-form',{staticClass:"form",on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_c('div',{staticClass:"alert fade alert-danger",class:{ show: _vm.errors.length },attrs:{"role":"alert"}},_vm._l((_vm.errors),function(error,i){return _c('div',{key:i,staticClass:"alert-text"},[_vm._v(" "+_vm._s(error)+" ")])}),0),_c('b-form-group',[(_vm.dialog)?_c('RoleSelect',{attrs:{"disabled":"","title":'Application',"placeholder":"Select an Application","endpoint":"api/applications","selected":{ id: _vm.selectedApp.id, name: _vm.selectedApp.name },"onSelected":function (val) {
                      _vm.selectedApp = val;
                      _vm.selectedApp.id = val ? val.id : null;
                      _vm.form.app_id = val.id;
                      _vm.search = '';
                      _vm.data = [];
                      _vm.page = 1;
                      _vm.loading = true;

                      _vm.getData();
                    }}}):_vm._e()],1),_c('b-form-group',[(_vm.dialog)?_c('RoleSelect',{attrs:{"disabled":"","title":'Role',"placeholder":"Select a Role","endpoint":"api/roles","selected":{ id: _vm.selectedRole.id, name: _vm.selectedRole.name },"onSelected":function (val) {
                      _vm.selectedRole = val;
                      _vm.selectedRole.id = val ? val.id : null;
                      _vm.form.role_id = val.id;
                      _vm.search = '';
                      _vm.data = [];
                      _vm.page = 1;
                      _vm.loading = true;

                      _vm.getData();
                    }}}):_vm._e(),_c('b-form-invalid-feedback',{attrs:{"id":"input-1-live-feedback"}},[_vm._v(" Role is required. ")])],1),_c('b-form-group',[(_vm.dialog && _vm.selectedApp.id)?_c('RoleSelect',{attrs:{"height":"100px","title":'Modul',"placeholder":"Select a Module","endpoint":("api/modules?app_id=" + (_vm.selectedApp.id)),"onSelected":function (val) { return (_vm.form.module_id = val.id); }}}):_vm._e(),_c('b-form-invalid-feedback',{attrs:{"id":"input-1-live-feedback"}},[_vm._v(" Module is required. ")])],1),_c('button',{directives:[{name:"ripple",rawName:"v-ripple"}],ref:"kt_submit",staticClass:"btn btn-primary mx-auto d-block font-weight-bolder px-12 py-3 mb-3 mt-5 font-size-3",staticStyle:{"border-radius":"100px"}},[_vm._v(" Submit ")])],1)],1)],1)],1)])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }